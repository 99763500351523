import './loader.scss';

import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';

import { CircularProgress } from '@material-ui/core';

import { ILoaderState } from '../../../store/loader/types';

interface ILoaderProps {
	loader: ILoaderState;
}

const Loader: FC<ILoaderProps> = ({ loader }): any => {
	return (
		<div className='loader-container'>
			<div className={`backdrop ${loader.isBusy ? 'show' : 'hide'}`}>
				<CircularProgress className='loader-spinner' />
			</div>
		</div>
	);
};
const mapStateToProps = (state: RootState) => ({
	loader: state.loader,
});
export default connect(mapStateToProps)(Loader);
