export interface INavBarState {
	isMenuOpen: boolean;
	disabledNext: boolean;
	disabledPrev: boolean;
	visibleNext: boolean;
	visiblePrev: boolean;
}

///
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const MENU_SET_NEXT = 'MENU_SET_NEXT';
export const MENU_SET_PREV = 'MENU_SET_PREV';
export const MENU_SET_VISIBLE_NEXT = 'MENU_SET_VISIBLE_NEXT';
export const MENU_SET_VISIBLE_PREV = 'MENU_SET_VISIBLE_PREV';

interface IMenuToggleAction {
	type: typeof MENU_TOGGLE;
}

interface IMenuSetNext {
	type: typeof MENU_SET_NEXT;
	flag: boolean;
}

interface IMenuSetPrev {
	type: typeof MENU_SET_PREV;
	flag: boolean;
}

interface IMenuSetVisiblePrev {
	type: typeof MENU_SET_VISIBLE_PREV;
	visible: boolean;
}

interface IMenuSetVisibleNext {
	type: typeof MENU_SET_VISIBLE_NEXT;
	visible: boolean;
}

export type NavBarActionTypes =
	| IMenuToggleAction
	| IMenuSetNext
	| IMenuSetPrev
	| IMenuSetVisiblePrev
	| IMenuSetVisibleNext;
