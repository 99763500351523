import React, { FC } from 'react';

interface IShipListReportProps {
  history: any;
  location: any;
}

const ShipListReport: FC<IShipListReportProps> = (): any => {
	return (
		<div>
		<iframe title="Tableau Report" src="https://sharedtableaudev2.inbcu.com/t/EMDTableau/views/DRAFT_ShipListv6_1_v2019_1/ShipListReport?iframeSizedToWindow=true&amp;:embed=y&amp;:showAppBanner=false&amp;:display_count=no&amp;:showVizHome=no" width="100%" height="1440"></iframe>
	</div>
	);
};

export default ShipListReport;
