import { Container, Typography } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import Loader from 'views/components/loader/loader';
import NavbarTop from 'views/components/navbar/navbarTop';
import { ILoaderState } from '../../store/loader/types';
import { INavBarState } from '../../store/navbar/types';
import AppRoutes from '../routes/routes';
import './app.scss';

interface IAppProps {
	//doExample: typeof doExample;
	//doExampleWithArg: typeof doExampleWithArg;
	history: History;
	loader: ILoaderState;
	navbar: INavBarState;
}

class App extends React.Component<IAppProps> {
	componentDidMount() {}
	render() {
		return (
			<ConnectedRouter history={this.props.history}>
				<Loader></Loader>
				<div className='root-container'>
					<NavbarTop isMenuOpen={false} history={this.props.history} />
					<div className='content-container'>
						<Container maxWidth='xl' className='app-page-container'>
							<Typography
								className='content-header'
								align='left'
								color='textPrimary'
							>
								<span>Page title here?</span>
							</Typography>
							<AppRoutes />
						</Container>
					</div>
				</div>
			</ConnectedRouter>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	loader: state.loader,
	navbar: state.navbar,
});
export default connect(mapStateToProps)(App);
