import {
	INavBarState,
	MENU_SET_NEXT,
	MENU_SET_PREV,
	MENU_SET_VISIBLE_NEXT,
	MENU_SET_VISIBLE_PREV,
	MENU_TOGGLE,
	NavBarActionTypes,
} from './types';

const initialState: INavBarState = {
	isMenuOpen: false,
	disabledNext: false,
	disabledPrev: false,
	visibleNext: true,
	visiblePrev: false,
};

const navBarReducer = (
	state = initialState,
	action: NavBarActionTypes
): INavBarState => {
	switch (action.type) {
		case MENU_TOGGLE:
			state.isMenuOpen = !state.isMenuOpen;
			return {
				...state,
			};
		case MENU_SET_NEXT:
			state.disabledNext = !action.flag;
			return {
				...state,
			};
		case MENU_SET_PREV:
			state.disabledPrev = !action.flag;
			return {
				...state,
			};
		case MENU_SET_VISIBLE_NEXT:
			state.visibleNext = action.visible;
			return {
				...state,
			};
		case MENU_SET_VISIBLE_PREV:
			state.visiblePrev = action.visible;
			return {
				...state,
			};
		default:
			return state;
	}
};

export default navBarReducer;
