import { ILoaderState, LOADER_BUSY, LOADER_IDLE, LoaderActionType } from './types';

const initialState: ILoaderState = {
  isBusy: false,
};

const loaderReducer = (
  state = initialState,
  action: LoaderActionType
): ILoaderState => {
  switch (action.type) {
    case LOADER_BUSY:
      state.isBusy = true;
      return { ...state, isBusy: true };
    case LOADER_IDLE:
      state.isBusy = false;
      return { ...state, isBusy: false };
    default:
      return state;
  }
};

export default loaderReducer;
