import React, { FC } from 'react';
import { Route, RouteProps, Switch } from 'react-router';
import Home from '../components/home/home';
import { CreativeStatusReport,
  ShipListReport,
  FinancialReport} from '../pages/tableau';
/**
 * TODO: let's think about which would be easier to maintain
 * 1) component + route direcly embedded in TSX
 * 2) component is in single config location and let component to render
 * 		children, etc
 * I'm still not very sure which is considered as preferred practice
 */
const AppRoutes: FC<RouteProps> = (props: any) => {
	return (
		<div className='routes-container'>
			<Switch>
				<Route exact path='/' component={Home} />
				<Route path='/reports/creativestatus' component={CreativeStatusReport} />
				<Route path='/reports/shiplist' component={ShipListReport} />
				<Route path='/reports/financial' component={FinancialReport} />
			</Switch>
		</div>
	);
};
export default AppRoutes;
