import React, { FC } from 'react';

interface IFinancialReportProps {
  history: any;
  location: any;
}

const FinancialReport: FC<IFinancialReportProps> = (): any => {
	return (
    <div>
    <iframe title="Tableau Report" src="https://sharedtableaudev2.inbcu.com/t/EMDTableau/views/CS-DRAFT_FinancialReport_v9_v2019_1/FinanceReport?iframeSizedToWindow=true&amp;:embed=y&amp;:showAppBanner=false&amp;:display_count=no&amp;:showVizHome=no" width="100%" height="1440"></iframe>
  </div>
	);
};

export default FinancialReport;
