import { StylesProvider, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'views/pages/app';
import * as serviceWorker from './core/service-worker/serviceWorker';
import { history, store } from './store/index';
import './views/styles/index.css';
import { nbcuTheme } from './views/styles/theme';

// enable console debugger for non-prod env
if (process.env.NODE_ENV !== 'production') {
	localStorage.setItem('debug', `${process.env.REACT_APP_NAME}:*`);
}

// fix limitation of Redux Dev tool
if (process.env.NODE_ENV === 'development') {
	(Map.prototype as any).toJSON = function () {
		return JSON.parse(JSON.stringify([...this]));
	};
}

// const store = configureStore();
const Root = () => (
	<ThemeProvider theme={nbcuTheme}>
		<StylesProvider injectFirst>
			<Provider store={store}>
			<App history={history}></App> 
			</Provider>
		</StylesProvider>
	</ThemeProvider>
);
ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
