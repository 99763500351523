import React, { FC } from 'react';
import './home.scss';

const Home: FC = (): any => {
	return (
		<div className='home-container'>
			<h1>Home Page</h1>
		</div>
	);
};

export default Home;
