import {
	AppBar,
	createStyles,
	Grid,
	Hidden,
	IconButton,
	makeStyles,
	Theme,
	Toolbar,
	Typography,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import './navbarTop.scss';
import NbcuLogoSvg from './nbcuLogo';

interface INavbarTopProp {
	history: any;
	isMenuOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		menuButton: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
		},
		title: {
			textAlign: 'center',
			flexGrow: 1,
			fontWeight: 300,
		},
		menuAndLogo: {
			display: 'flex',
			flexGrow: 0,
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '100%',
			justifyContent: 'flex-start',
		},
		user: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		userName: {
			flexGrow: 0,
			paddingRight: theme.spacing(2),
			display: 'flex',
			alignItems: 'center',
			alignContent: 'flex-end',
			fontWeight: 300,
		},
	})
);

const NavbarTop: FC<INavbarTopProp> = ({
	history,
	isMenuOpen
}): any => {
	const login = () => {
		// redirect user to SSO login page
		history.push('/');
	};

	const classes = useStyles();
	return (
		<React.Fragment>
			<AppBar position='fixed'>
				<Toolbar className='toolbar-container'>
					<Grid container spacing={1} alignContent='center' alignItems='center'>
						<Grid item xs={6} sm={3}>
							<div className={classes.menuAndLogo}>
								<IconButton
									edge='start'
									className={classes.menuButton}
									color='inherit'
									aria-label='menu'
									onClick={() => {
										history.push('/');
									}}
								>
									<MenuIcon />
								</IconButton>
								{/* logo will be hidden <= sm */}
								<Hidden smDown>
									<NbcuLogoSvg
										muiname='SvgIcon'
										displayname='SvgNbcUniversal'
									></NbcuLogoSvg>
								</Hidden>
							</div>
						</Grid>
						{/* title will be hidden <= xs, but >= sm will be visible */}
						<Hidden xsDown>
							<Grid item sm={6}>
								<Typography className={classes.title} variant='h6' noWrap>
									NBC Entertainment, Marketing & Digital Requests
								</Typography>
							</Grid>
						</Hidden>
						{/* user login status will be shown all the time */}
						<Grid item xs={6} sm={3}>
							<div className={classes.user}>
								<IconButton aria-label='User' color='inherit' onClick={login}>
									<AccountCircle />
								</IconButton>
								<Typography className={classes.userName} variant='h6' noWrap>
									User Name Here
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
};

const mapStateToProps = (state: RootState) => ({
	navbar: state.navbar,
});

export default connect(mapStateToProps)(NavbarTop);
