import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { extActionMiddleware } from '../core/middlewares/extActionMiddleware';
import loaderReducer from './loader/reducers';
import navBarReducer from './navbar/reducers';

// store configuration and middleware setups
export const history = createBrowserHistory();

const rootReducer = combineReducers({
	loader: loaderReducer,
	navbar: navBarReducer,
	router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>;

const configureStore = () => {
	const middlewares = [
		thunkMiddleware,
		extActionMiddleware,
		routerMiddleware(history),
	];
	const middleWareEnhancer = applyMiddleware(...middlewares);
	const store = createStore(
		rootReducer,
		composeWithDevTools(middleWareEnhancer)
	);

	return store;
};
export const store = configureStore();
