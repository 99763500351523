import React, { FC } from 'react';

interface ICreativeStatusReportProps {
  history: any;
  location: any;
}

const CreativeStatusReport: FC<ICreativeStatusReportProps> = (): any => {
	return (
		<div>
			<iframe title="Tableau Report" src="https://sharedtableaudev2.inbcu.com/t/EMDTableau/views/DRAFT_CreativeDailyReportv10_1_v2019_1/CreativeDailyReport?iframeSizedToWindow=true&amp;:embed=y&amp;:showAppBanner=false&amp;:display_count=no&amp;:showVizHome=no" width="100%" height="1440"></iframe>
    </div>
	);
};

export default CreativeStatusReport;
