import { createMuiTheme } from '@material-ui/core';

// this overrides default color palette of Material UI
// to follow NBCU specific color theme (purple color based)
export const nbcuTheme = createMuiTheme({
	palette: {
		primary: {
			light: '#8561c5',
			main: '#673ab7',
			dark: '#482880',
			contrastText: '#fff',
		},
		secondary: {
			light: '#834bff',
			main: '#651fff',
			dark: '#4615b2',
			contrastText: '#000',
		},
		error: {
			light: '#ff8a50',
			main: '#ff5722',
			dark: '#c41c00',
			contrastText: '#000',
		},
		// ! dead code, but left it for reference reason
		// action: {
		// 	selected: '#673ab7',
		// 	hover: '#834bff',
		// 	disabled: '#9B9B9B',
		// },
	},
	overrides: {
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: 'rgba(255,255,255,1)',
				},
			},
		},
		MuiChip: {
			root: {
				backgroundColor: '#673ab7',
				color: '#fff',
			},
			deleteIcon: {
				fill: '#fff',
				stroke: '#673ab7',
				// backgroundColor: '#fff',
				'&:hover': {
					fill: '#fff',
					backgroundColor: '#673ab7',
				},
			},
		},
		// ! dead code, but left it for reference reason
		// MuiInputLabel: {
		// 	// Name of the component ⚛️ / style sheet
		// 	root: {
		// 		color: '#8561c5',
		// 		'&$focused': {
		// 			// increase the specificity for the pseudo class
		// 			color: '#8561c5',
		// 		},
		// 	},
		// },
		// MuiMenuItem: {
		// 	root: {
		// 		'&:hover': {
		// 			backgroundColor: 'transparent',
		// 		},
		// 	},
		// },
		// MuiListItem: {
		// 	root: {
		// 		'&$selected': {
		// 			backgroundColor: '#673ab7',
		// 			color: '#fff',
		// 			'&:hover': {
		// 				backgroundColor: '#673ab7',
		// 			},
		// 		},
		// 	},
		// 	button: {
		// 		'&:hover': {
		// 			backgroundColor: '#673ab7',
		// 		},
		// 	},
		// },
	},
});
