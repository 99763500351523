export interface ILoaderState {
  isBusy: boolean;
}

///
export const LOADER_BUSY = 'LOADER_BUSY';
export const LOADER_IDLE = 'LOADER_IDLE';

interface ILoaderBusyAction {
  type: typeof LOADER_BUSY;
}

interface ILoaderIdleAction {
  type: typeof LOADER_IDLE;
}

export type LoaderActionType = ILoaderBusyAction | ILoaderIdleAction;
